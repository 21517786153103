// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/18
// +-------------------------------------------------------------
// |Time: 13:51
// +-------------------------------------------------------------
import {createStore} from "vuex";
import {ruleList} from "@/api/auth/menu";
import {info} from "@/api/login";
import {stsToken, stsTokenImage, txStsToken} from "@/api/tool";
import {getTime, utcToTimestamp} from "@/utils/time";

export default createStore({
    state:{
        // 菜单栏收缩
        collapse: false,
        // 分帧标签
        tagsList: [],
        // 权限列表
        authRule: [],
        // 用户信息
        userInfo: [],
        // 阿里云上传
        stsToken: {
            SecurityToken: '',
            AccessKeyId: '',
            AccessKeySecret: '',
        },
        // 阿里云上传图片
        stsTokenImage: {
            SecurityToken: '',
            AccessKeyId: '',
            AccessKeySecret: '',
        },
        // 腾讯云云上传
        txStsToken: {
            TmpSecretId: '',
            TmpSecretKey:'' ,
            SecurityToken: '',
            StartTime:'', // 时间戳，单位秒，如：1580000000
            ExpiredTime:'',
        },
        // 回调路径
        callbackRoute:''
    },
    mutations: {
        login(state, data) {
            let tokenName = window.self !== window.top ? 'admin_self_token' : 'admin_token'
            let idName = window.self !== window.top ? 'admin_self_id' : 'admin_id'
            localStorage.setItem(tokenName, data.token);
            localStorage.setItem(idName, data.id);
        },
        logout(state) {
            let tokenName = window.self !== window.top ? 'admin_self_token' : 'admin_token'
            let idName = window.self !== window.top ? 'admin_self_id' : 'admin_id'
            localStorage.removeItem(tokenName)
            localStorage.removeItem(idName)
            localStorage.removeItem('setk')
            state.tagsList = [];
            state.authRule = [];
            state.userInfo = [];
        },
        updateRuleList(state,data) {
            state.authRule = data
        },
        updateUserInfo(state,data) {
            state.userInfo = data
        },
        // 删除标签页
        delTagsItem(state, data) {
            state.tagsList.splice(data.index, 1)
        },
        // 增加标签页
        setTagsItem(state, data) {
            state.tagsList.push(data)
        },
        // 清除标签页
        clearTags(state) {
            state.tagsList = []
        },
        // 清除其他标签页
        closeTagsOther(state, data) {
            state.tagsList = data;
        },
        // 关闭当前标签页
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i];
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data.$router.push(state.tagsList[i + 1].path);
                    } else if (i > 0) {
                        data.$router.push(state.tagsList[i - 1].path);
                    } else {
                        data.$router.push("/");
                    }
                    state.tagsList.splice(i, 1);
                    break;
                }
            }
        },
        // 侧边栏折叠
        handleCollapse(state, data) {
            state.collapse = data;
        },
        updateCallbackRoute(state, data) {
            state.callbackRoute = data
        },
    },
    getters:{
        getLocalStorage: function () {
            let tokenName = window.self !== window.top ? 'admin_self_token' : 'admin_token'
            let idName = window.self !== window.top ? 'admin_self_id' : 'admin_id'
            return {
                token: localStorage.getItem(tokenName) || '',
                uid: localStorage.getItem(idName) || 0
            }
        },
        async stsToken(state) {
            // stsToken存在 且当前时间在有效期内
            if (state.stsToken.AccessKeyId && (getTime() + 300) < state.stsToken.Expiration) {
                return state.stsToken
            }
            await stsToken().then(res => {
                state.stsToken.SecurityToken = res.data.Credentials.SecurityToken;
                state.stsToken.AccessKeyId = res.data.Credentials.AccessKeyId;
                state.stsToken.AccessKeySecret = res.data.Credentials.AccessKeySecret;
                state.stsToken.Expiration = utcToTimestamp(res.data.Credentials.Expiration);
            })
            return state.stsToken
        },
        async stsTokenImage(state) {
            // stsToken存在 且当前时间在有效期内
            if (state.stsTokenImage.AccessKeyId && (getTime() + 300) < state.stsTokenImage.Expiration) {
                return state.stsTokenImage
            }
            await stsTokenImage().then(res => {
                state.stsTokenImage.SecurityToken = res.data.Credentials.SecurityToken;
                state.stsTokenImage.AccessKeyId = res.data.Credentials.AccessKeyId;
                state.stsTokenImage.AccessKeySecret = res.data.Credentials.AccessKeySecret;
                state.stsTokenImage.Expiration = utcToTimestamp(res.data.Credentials.Expiration);
            })
            return state.stsTokenImage
        },
        async txStsToken(state) {
            // stsToken存在 且当前时间在有效期内
            if (state.txStsToken.tmpSecretId && (getTime() + 300) < state.txStsToken.ExpiredTime) {
                return state.txStsToken
            }
            await txStsToken().then(res => {
                state.txStsToken.TmpSecretId = res.data.credentials.tmpSecretId;
                state.txStsToken.TmpSecretKey = res.data.credentials.tmpSecretKey;
                state.txStsToken.SecurityToken = res.data.credentials.sessionToken;
                state.txStsToken.StartTime = res.data.startTime;
                state.txStsToken.ExpiredTime = res.data.expiredTime;
            })
            return state.txStsToken
        }
    },
    actions:{
        getUserInfo({commit}){
            return info().then(({data})=>{
                commit('updateUserInfo',data)
            })
        },
        async getRuleList({commit}){
            const {data} = await ruleList()
            commit('updateRuleList',data)
            return data
        }
    },
    modules:{},
})
