// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/18
// +-------------------------------------------------------------
// |Time: 14:05
// +-------------------------------------------------------------

import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store/index";
import Auth from '@/router/model/auth'
import Game from '@/router/model/game'
import User from '@/router/model/user'
import Trade from '@/router/model/trade'
import Promote from '@/router/model/promote'
import App from '@/router/model/app'
import Config from '@/router/model/config'
import Data from "@/router/model/data";
import {checkWebsiteAccessibility} from "@/utils/common";
const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },
    {
        path: "/",
        name: "adminHome",
        component:()=>import("@/views/Home.vue"),
        children:[
            {
                path: "refresh",
                name: "VRefresh",
                meta: {
                    title: "刷新"
                },
                component: () => import ("@/views/Refresh.vue")
            },
            {
                path: 'user-info',
                name: "UserInfo",
                meta: {
                    title: "个人中心"
                },
                component: () => import("@/views/UserInfo.vue")
            },
            {
                path: 'dashboard',
                name: "VDashboard",
                meta: {
                    title: "系统首页"
                },
                component: () => import("@/views/Dashboard.vue")
            },
            ...Auth,
            ...Game,
            ...User,
            ...Trade,
            ...Promote,
            ...App,
            ...Config,
            ...Data
        ]
    },
    {
        path: '/discount-quote',
        name: "discountQuoteList",
        meta: {
            title: "折扣报价"
        },
        component: () => import("@/views/game/discountQuote/Index.vue")
    },
    {
        path: '/login',
        name: "VLogin",
        meta: {
            title: "登录"
        },
        component: () => import('@/views/Login')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

//渲染一个路由的过程中，尝试解析一个异步组件时发生的错误
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if (isChunkLoadFailed && router?.history?.pending) {
        const targetPath = router.history.pending.fullPath;
        router.replace(targetPath);
    }
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | 青菜手游管理后台`;
    if(localStorage.getItem('setk') === null){
        checkWebsiteAccessibility()
    }
    // 判断是否登录
    const localStorageData = store.getters['getLocalStorage'];
    if ((!localStorageData?.uid || !localStorageData?.token) && to.path !== '/login') {
        store.commit("updateCallbackRoute",to.path);
        next('/login');
    } else {
        next();
    }
})

export default router
