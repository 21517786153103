// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/11/28
// +-------------------------------------------------------------
// |Time: 10:58
// +-------------------------------------------------------------
import useClipboard from "vue-clipboard3";
import {ElMessage, ElMessageBox} from "element-plus";
import axios from "axios";

const {toClipboard} = useClipboard();
export const asyncCopy = async (msg) => {
    try {
        await toClipboard(msg);
        ElMessage.success('复制成功')
    } catch (e) {
        ElMessageBox.alert(msg, '', {closeOnClickModal: true, showConfirmButton: false})
    }
}

export function checkWebsiteAccessibility() {
    axios.get(process.env.VUE_APP_QINGCAI_CHECK, {
        timeout: 1000 // 设置超时时间为10000毫秒（10秒）
    }).then(() => {
        localStorage.setItem('setk', 1)
    }).catch(() => {
        localStorage.setItem('setk', 0)
    })
}